define("auth-frontend/templates/parent/permissions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EZTyZPgr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ten wide left aligned column\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,1,\"perm-header\"],[12],[2,\"Permission: \"],[1,[35,3,[\"description\"]]],[2,\" (\"],[1,[35,3,[\"system\"]]],[2,\") \"],[10,\"em\"],[14,0,\"grey text\"],[12],[1,[30,[36,2],[[35,4],\"(editing)\",\"\"],null]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"right aligned right floated six wide column\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,\"data-position\",\"top right\"],[14,\"data-tooltip\",\"Edit Permission\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"blue big edit icon\"],[15,\"onClick\",[30,[36,0],[[32,0],\"setEditing\",true],null]],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"permission\",[],[[\"@model\",\"@editing\",\"@cancel\",\"@setEditing\"],[[34,3],[34,4],[34,6],[34,7]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hasEditPerm\",\"if\",\"model\",\"editing\",\"unless\",\"cancel\",\"setEditing\"]}",
    "moduleName": "auth-frontend/templates/parent/permissions/edit.hbs"
  });

  _exports.default = _default;
});