define("auth-frontend/controllers/application", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    router: Ember.inject.service(),
    oneTrackRoute: Ember.computed('router.currentURL', function () {
      var location = this.get('router.currentURL').split('?').get('firstObject').split('/').get('lastObject');
      return _environment.default.oneTrackRoutes.includes("/".concat(location));
    })
  });

  _exports.default = _default;
});