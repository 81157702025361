define("auth-frontend/templates/components/audit-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YNeYgoua",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,0,[\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"audit-show\"],[4,[38,1],[[32,0],\"toggleAudits\"],null],[12],[1,[35,0,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"history-show\"],[4,[38,1],[[32,0],\"toggleHistory\"],null],[12],[10,\"i\"],[14,0,\"icon history\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"audit-name\"],[12],[1,[35,0,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"grantedBy\",\"action\",\"if\",\"tempRelation\",\"unless\"]}",
    "moduleName": "auth-frontend/templates/components/audit-link.hbs"
  });

  _exports.default = _default;
});