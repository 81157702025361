define("auth-frontend/templates/components/table-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rWtVj58v",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,1,\"empty-table\"],[24,0,\"ui segment\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "auth-frontend/templates/components/table-message.hbs"
  });

  _exports.default = _default;
});