define("auth-frontend/models/audit", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    'to': (0, _model.attr)('string'),
    'to_type': (0, _model.attr)('string'),
    'at': (0, _model.attr)('string'),
    'within': (0, _model.attr)('string'),
    'added': (0, _model.attr)('string'),
    'added_type': (0, _model.attr)('string'),
    'by': (0, _model.attr)('string'),
    'by_type': (0, _model.attr)('string'),
    'deleted': (0, _model.attr)('string'),
    'deleted_by': (0, _model.attr)('string'),
    'audits': (0, _model.hasMany)('audit', {
      'inverse': null
    })
  });

  _exports.default = _default;
});