define("auth-frontend/initializers/key-manager-config", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    var keyManagerConfig = Ember.get(_environment.default, 'keyManagerConfig') || {};
    application.register('main:key-manager-config', keyManagerConfig, {
      instantiate: false
    });
  }

  var _default = {
    name: 'key-manager-config',
    initialize: initialize
  };
  _exports.default = _default;
});