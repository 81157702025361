define("auth-frontend/components/audit-display", ["exports", "ember-data", "apps-core-ui/components/core-modal"], function (_exports, _emberData, _coreModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _coreModal.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    store: Ember.inject.service(),
    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);

      this.eventbus.on('toggle-audit-display', this, 'toggle');
    },
    buildAuditQuery: function buildAuditQuery() {
      var deleted = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var obj = {};
      var modelAName = this.get('modelA.constructor.modelName');
      var modelBName = this.get('modelB.constructor.modelName');
      obj["filter[".concat(modelAName, "]")] = this.get('modelA.id');
      obj["filter[".concat(modelBName, "]")] = this.get('modelB.id');

      if (this.get('thirdParam.group.id')) {
        obj['filter[group]'] = this.get('thirdParam.group.id');
      }

      if (deleted) {
        obj['filter[deleted]'] = 1;
      }

      return obj;
    },
    getSortedAudits: function getSortedAudits(deleted) {
      return _emberData.default.PromiseArray.create({
        promise: this.store.query('audit', this.getFullQuery(this.buildAuditQuery(deleted))).then(function (audits) {
          return audits.sortBy('at').reverse().map(function (audit) {
            if (audit.get('audits')) {
              audit.set('audits', audit.get('audits').sortBy('at').reverse());
            }

            return audit;
          });
        })
      });
    },
    sortedAudits: Ember.computed('modelA', 'modelB', 'showAudit', function () {
      return this.getSortedAudits(false);
    }),
    sortedHistories: Ember.computed('modelA', 'modelB', function () {
      return this.getSortedAudits(true);
    }),
    toggle: function toggle(modelA, modelB, thirdParam, agency, inherited) {
      var history = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      this.setProperties({
        'showAudit': !history,
        'showHistory': history,
        'modelA': modelA,
        'modelB': modelB,
        'thirdParam': thirdParam,
        'agency': agency,
        'inherited': inherited
      });
      this.execute('toggle');
    },
    getFullQuery: function getFullQuery(obj) {
      if (this.agency) {
        obj['filter[agency]'] = this.agency;
      }

      if (!this.inherited) {
        obj.inherited = 0;
      }

      if (this.inherited && this.agency) {
        obj.master_only = 1;
      }

      return obj;
    },
    closeModal: function closeModal() {
      this.execute('hide');
    },
    toggleHistory: function toggleHistory() {
      this.set('showHistory', true);
    }
  }, (_applyDecoratedDescriptor(_obj, "closeModal", [_dec], Object.getOwnPropertyDescriptor(_obj, "closeModal"), _obj), _applyDecoratedDescriptor(_obj, "toggleHistory", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleHistory"), _obj)), _obj)));

  _exports.default = _default;
});