define("auth-frontend/components/core-modal", ["exports", "apps-core-ui/components/core-modal"], function (_exports, _coreModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _coreModal.default;
    }
  });
});