define("auth-frontend/templates/parent/company-permissions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/I8tpRp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui warning message\"],[12],[2,\"\\n        Prior to removing permissions from company, please remove the permission from all groups and users\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"parent.permissions\"]],[[\"default\"],[{\"statements\":[[2,\"here.\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ten wide left aligned column\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,1,\"permissions-type-heading\"],[12],[2,\"Company Permissions\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"right aligned right floated six wide column\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"right aligned right floated header inline\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,\"data-position\",\"top right\"],[14,\"data-tooltip\",\"Create Permission\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"black big add icon\"],[15,\"onClick\",[30,[36,0],[[32,0],\"addPerm\"],null]],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"permissions\",[],[[\"@showCheckbox\",\"@showToggle\",\"@showDropdown\",\"@showEdit\",\"@rowMenuVisible\",\"@parent\",\"@relationField\",\"@autoSave\",\"@permissions\",\"@view\",\"@edit\",\"@hideUsersGroups\"],[true,true,true,[34,1],true,[34,3],\"companyPermissions\",true,[34,4],[34,5],[34,6],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isSuperuser\",\"if\",\"model\",\"permissions\",\"view\",\"edit\"]}",
    "moduleName": "auth-frontend/templates/parent/company-permissions/index.hbs"
  });

  _exports.default = _default;
});