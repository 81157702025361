define("auth-frontend/templates/components/table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KNLn93US",
    "block": "{\"symbols\":[\"@allSelected\",\"@toggleAll\",\"@checkboxLabel\",\"@showCheckbox\",\"&default\"],\"statements\":[[10,\"thead\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"th\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ui checkbox\"],[14,1,\"toggle-all\"],[12],[2,\"\\n          \"],[10,\"input\"],[15,\"checked\",[32,1]],[15,\"onChange\",[30,[36,1],[[32,0],[30,[36,0],[[32,2]],null]],null]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n          \"],[10,\"label\"],[12],[1,[32,3]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"action\",\"if\"]}",
    "moduleName": "auth-frontend/templates/components/table-header.hbs"
  });

  _exports.default = _default;
});