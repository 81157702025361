define("auth-frontend/controllers/parent", ["exports", "jquery", "auth-frontend/config/environment", "auth-frontend/utils/download"], function (_exports, _jquery, _environment, _download) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    companyNumber: Ember.inject.service(),
    userPermissions: Ember.inject.service(),
    downloading: false,
    user: Ember.computed(function () {
      return this.store.peekRecord('user', 'details');
    }),
    authURL: Ember.computed(function () {
      return _environment.default.authUrl;
    }),
    selfEditing: function selfEditing() {
      var location = this.get('router.currentURL').split('?').get('firstObject');

      if (location.includes('users/')) {
        return location.split('/').get('lastObject') === 'details';
      }

      return false;
    },
    oneTrackRoute: Ember.computed('router.currentURL', function () {
      var location = this.get('router.currentURL').split('?').get('firstObject').split('/').get('lastObject');
      return _environment.default.oneTrackRoutes.includes("/".concat(location)) || this.selfEditing();
    }),
    userIsStaff: Ember.computed(function () {
      return this.get('user.isStaff');
    }),
    permissions: Ember.computed('user', function () {
      return this.userPermissions.getPermissions('auth');
    }),
    downloadXLSX: function downloadXLSX() {
      var _this = this;

      _jquery.default.ajax({
        url: "".concat(_environment.default.authUrl, "/").concat(this.companyNumber.currentCompany, "/api/export/"),
        xhr: function xhr() {
          var xhr = new XMLHttpRequest();
          xhr.responseType = _download.default.responseType;
          return xhr;
        },
        success: function success(response) {
          Ember.run(function () {
            _this.set('downloading', false);

            _download.default.save(response, 'user-permissions.xlsx');
          });
        },
        error: function error() {
          Ember.run(function () {
            _this.set('downloading', false);

            _this.flashMessages.negative('There was en error exporting user permissions. Please try again.');
          });
        }
      });
    },
    export: function _export() {
      this.set('downloading', true);
      this.flashMessages.info('The export file is being created, please do not close this window. The file will automatically download once it is ready.'); //eslint-disable-line

      this.downloadXLSX();
      gtag('event', 'export-permissions', {
        company: this.companyNumber.currentCompany
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "export", [_dec], Object.getOwnPropertyDescriptor(_obj, "export"), _obj)), _obj)));

  _exports.default = _default;
});