define("auth-frontend/components/user-agencies", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    context: _environment.default.semanticUIContext,
    hoverAgencies: Ember.computed(function () {
      var output = '';
      this.popupAgencies.forEach(function (agency) {
        output += "".concat(agency);
      });
      return output;
    }),
    agency: Ember.computed('agencyCodes.[]', 'agencies.[]', function () {
      var _this = this;

      var agencies = this.agencies.filter(function (agency) {
        return _this.agencyCodes.includes(agency.get('agencyCode'));
      });

      if (agencies.length > 1) {
        var popupAgencies = agencies.map(function (agency) {
          return "".concat(agency.get('name'), " [").concat(agency.get('agencyCode'), "]<br>");
        });
        this.set('popupAgencies', popupAgencies);
      }

      return agencies;
    })
  });

  _exports.default = _default;
});