define("auth-frontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9n5td558",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[8,\"core-body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,4],[[35,3],\"one\",\"two\"],null],\" track app-area\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"app-content\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"arrangedQueue\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"column messages\"],[12],[2,\"\\n            \"],[8,\"ui-sticky\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"flash-message\",[],[[\"@flash\",\"@class\",\"@messageStyle\"],[[32,1],\"ui message\",\"foundation\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"confirmation-modal\",[],[[],[]],null],[2,\"\\n\"],[8,\"audit-display\",[],[[],[]],null],[2,\"\\n\"],[8,\"compare-modal\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flashMessages\",\"-track-array\",\"each\",\"oneTrackRoute\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "auth-frontend/templates/application.hbs"
  });

  _exports.default = _default;
});