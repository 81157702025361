define("auth-frontend/components/core-body", ["exports", "apps-core-ui/components/core-body", "auth-frontend/config/environment"], function (_exports, _coreBody, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _coreBody.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    permissions: Ember.computed(function () {
      return {
        dashboard: true,
        claims: false,
        search: false,
        appMenu: true,
        activity: false
      };
    }),
    applicationLinks: Ember.computed('user', 'userPermissions', function () {
      var links = [{
        link: this.router.urlFor('parent.users', {
          queryParams: {
            insuredsOnly: false,
            apiUsersOnly: false
          }
        }),
        name: 'Users'
      }, {
        link: this.router.urlFor('parent.users', {
          queryParams: {
            insuredsOnly: true
          }
        }),
        name: 'Insureds'
      }, {
        link: this.router.urlFor('parent.users', {
          queryParams: {
            apiUsersOnly: true
          }
        }),
        name: 'API'
      }, {
        linkTo: 'parent.groups',
        name: 'Groups'
      }, {
        linkTo: 'parent.permissions',
        name: 'Permissions'
      }];

      if (this.userPermissions.getPermissions('auth').manageCompanyPermissions) {
        links.pushObject({
          linkTo: 'parent.company-permissions',
          name: 'Company Permissions'
        });
      }

      return links;
    }),
    zenddeskArticleIds: Ember.computed(function () {
      return {
        'parent.users.index': '360017965451',
        'parent.users.create': '360017965451',
        'parent.users.edit': '360017965451',
        'parent.groups.index': '360017965831',
        'parent.groups.create': '360017965831',
        'parent.groups.edit': '360017965831',
        'parent.permissions.index': '360017687192',
        'parent.permissions.edit': '360017687192',
        'parent.company-permissions.index': '360018660931',
        'parent.company-permissions.create': '360018660931',
        'parent.company-permissions.edit': '360018660931'
      };
    }),
    userLinks: Ember.computed('router.currentRouteName', 'zenddeskArticleIds', function () {
      var routeName = this.router.currentRouteName;
      var options = [];

      if (this.zenddeskArticleIds[routeName]) {
        options.pushObject({
          name: 'Help',
          link: true,
          target: '_blank',
          url: "".concat(_environment.default.zendeskUrl, "/").concat(this.zenddeskArticleIds[routeName]),
          icon: 'help',
          id: 'help-link'
        });
      }

      return options;
    }),
    company: Ember.computed('companyNumber', 'router.currentRoute.queryParams.api_key', 'session.data.authenticated.company_id', function () {
      var _this$router$currentR, _this$router$currentR2;

      var company_id = this.session.data.authenticated.company_id;
      var apiToken = (_this$router$currentR = this.router.currentRoute) === null || _this$router$currentR === void 0 ? void 0 : (_this$router$currentR2 = _this$router$currentR.queryParams) === null || _this$router$currentR2 === void 0 ? void 0 : _this$router$currentR2.api_key;

      if (company_id) {
        return this.store.findRecord('company', company_id);
      } else if (apiToken) {
        return this.store.findRecord('company', apiToken, {
          adapterOptions: {
            public: true
          }
        });
      }

      return undefined;
    })
  });

  _exports.default = _default;
});