define("auth-frontend/templates/components/table-row-menu-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AFRmTc8u",
    "block": "{\"symbols\":[\"&attrs\",\"@clickValue\",\"@clickAction\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"item\"],[17,1],[4,[38,1],[[32,0],[30,[36,0],[[32,3],[32,2]],null]],null],[12],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"action\"]}",
    "moduleName": "auth-frontend/templates/components/table-row-menu-button.hbs"
  });

  _exports.default = _default;
});