define("auth-frontend/templates/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IHQ3C9a+",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,0,\"show-details\"],[24,6,\"javascript:void(0)\"],[4,[38,0],[[32,0],\"toggleDetails\"],null],[12],[2,\"Details\"],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"iframe\"],[15,\"src\",[34,1]],[15,0,[31,[\"auth-logout-iframe \",[30,[36,3],[[35,2],\"hide-iframe\"],null]]]],[14,\"title\",\"Logout iframe\"],[14,\"height\",\"40\"],[14,\"scrolling\",\"no\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"legacyLogout\",\"showDetails\",\"unless\"]}",
    "moduleName": "auth-frontend/templates/logout.hbs"
  });

  _exports.default = _default;
});