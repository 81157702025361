define("auth-frontend/components/password-field", ["exports", "zxcvbn"], function (_exports, _zxcvbn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    passwordInputType: 'password',
    passwordStrength: 0,
    toggleShowPassword: function toggleShowPassword() {
      var value = this.passwordInputType === 'password' ? 'text' : 'password';
      this.set('passwordInputType', value);
    },
    keyUpAction: function keyUpAction() {
      if (!this.noBar) {
        var invalidPasswordContents = [this.userChangeset.get('username'), this.userChangeset.get('email'), this.userChangeset.get('firstName'), this.userChangeset.get('lastName')];
        var result = (0, _zxcvbn.default)(this.value, invalidPasswordContents);
        var barLookup = {
          '0': 0,
          '1': 10,
          '2': 30,
          '3': 60,
          '4': 100
        };
        this.setProperties({
          passwordStrength: barLookup["".concat(result.score)],
          passwordScore: result.score
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleShowPassword", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowPassword"), _obj), _applyDecoratedDescriptor(_obj, "keyUpAction", [_dec2], Object.getOwnPropertyDescriptor(_obj, "keyUpAction"), _obj)), _obj)));

  _exports.default = _default;
});