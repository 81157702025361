define("auth-frontend/controllers/forgot-password", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service(),
    showMessage: function showMessage() {
      this.flashMessages.positive('Password reset email successfully sent. Please check your email and follow instructions to reset your password.');
    },
    resetPassword: function resetPassword() {
      var _this = this;

      this.model.validate().then(function () {
        if (_this.get('model.isValid')) {
          _this.ajax.post("".concat(_environment.default.authUrl, "/api/forgot-password/"), {
            contentType: 'application/vnd.api+json',
            data: {
              data: {
                attributes: {
                  username: _this.get('model.username')
                },
                type: 'users'
              }
            }
          }).then(function () {
            _this.showMessage();
          }).catch(function () {
            _this.showMessage();
          });
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "resetPassword", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetPassword"), _obj)), _obj)));

  _exports.default = _default;
});