define("auth-frontend/templates/parent/permissions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oz6R1fI9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Permissions\"],[13],[2,\"\\n\"],[8,\"permissions\",[],[[\"@permissions\",\"@rowMenuVisible\",\"@showDropdown\",\"@showEdit\",\"@view\",\"@edit\"],[[34,0],true,true,true,[34,1],[34,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"view\",\"edit\"]}",
    "moduleName": "auth-frontend/templates/parent/permissions/index.hbs"
  });

  _exports.default = _default;
});