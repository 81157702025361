define("auth-frontend/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GodlcDG5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"login-form\",[],[[\"@next\",\"@model\"],[[34,0],[34,1]]],null],[2,\"\\n\"],[10,\"iframe\"],[15,\"src\",[34,2]],[15,0,[31,[\"auth-logout-iframe \",[30,[36,4],[[35,3],\"hide-iframe\"],null]]]],[14,\"title\",\"Login Iframe\"],[14,\"height\",\"40\"],[14,\"scrolling\",\"no\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"next\",\"model\",\"legacyLogout\",\"showDetails\",\"unless\"]}",
    "moduleName": "auth-frontend/templates/login.hbs"
  });

  _exports.default = _default;
});