define("auth-frontend/templates/components/table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pf+HZhFm",
    "block": "{\"symbols\":[\"@item\",\"@toggleChecked\",\"@showCheckbox\",\"&default\"],\"statements\":[[10,\"tr\"],[15,1,[31,[\"row-\",[32,1,[\"id\"]]]]],[12],[2,\"\\n\"],[6,[37,3],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[14,0,\"left collapsing aligned\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui checkbox\"],[12],[2,\"\\n        \"],[10,\"input\"],[15,\"checked\",[34,0]],[15,\"onChange\",[30,[36,2],[[32,0],[30,[36,1],[[32,2],[32,1]],null]],null]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n        \"],[10,\"label\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,4,[[30,[36,5],null,[[\"Menu\"],[[30,[36,4],[\"table-row-menu\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isChecked\",\"fn\",\"action\",\"if\",\"component\",\"hash\"]}",
    "moduleName": "auth-frontend/templates/components/table-row.hbs"
  });

  _exports.default = _default;
});