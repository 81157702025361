define("auth-frontend/utils/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTokenData = getTokenData;

  function getToken() {
    var token = false;
    var cookies = document.cookie.split(';');
    cookies.forEach(function (cookie) {
      if (cookie.trim().substring(0, 5) === 'auth=') {
        token = cookie.split('=')[1];
      }
    });
    return token;
  }

  function getTokenData() {
    var token = getToken();

    if (token) {
      var tokenData = JSON.parse(atob(token.split('.')[1]));
      tokenData.token = token;
      return tokenData;
    }
  }
});