define("auth-frontend/templates/components/audit-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m7ptPkPK",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[10,\"div\"],[15,0,[34,2]],[12],[2,\"\\n\"],[6,[37,3],[[35,0,[\"by_type\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"audit-type\"],[12],[1,[30,[36,1],[[35,0,[\"by_type\"]]],null]],[2,\":\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[15,0,[31,[[34,2],\" audit-by audit-field\"]]],[12],[1,[35,0,[\"by\"]]],[13],[2,\" added\\n  \"],[10,\"span\"],[14,0,\"audit-type\"],[12],[1,[35,0,[\"added_type\"]]],[2,\":\"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"audit-added audit-field\"],[12],[1,[35,0,[\"added\"]]],[13],[2,\" to\\n  \"],[10,\"span\"],[14,0,\"audit-type\"],[12],[1,[35,0,[\"to_type\"]]],[2,\":\"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"audit-to audit-field\"],[12],[1,[35,0,[\"to\"]]],[13],[2,\" on\\n  \"],[10,\"span\"],[14,0,\"audit-date\"],[12],[1,[30,[36,4],[[35,0,[\"at\"]],\"MM/DD/YYYY [at] h:mm a\"],null]],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,0,[\"audits\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[8,\"audit-item\",[],[[\"@audit\",\"@auditType\"],[[32,1],\"audit\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"audit\",\"capitalize\",\"auditType\",\"if\",\"moment-format\",\"-track-array\",\"each\"]}",
    "moduleName": "auth-frontend/templates/components/audit-item.hbs"
  });

  _exports.default = _default;
});