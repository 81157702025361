define("auth-frontend/utils/logout-user", ["exports", "apps-core-ui/utils/token", "auth-frontend/config/environment", "fetch"], function (_exports, _token, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = logOutUser;

  function logOutUser(obj) {
    var rawToken = (0, _token.getToken)(obj.get('router'));

    if (rawToken) {
      var token = (0, _token.getTokenData)(rawToken);
      return (0, _fetch.default)("".concat(_environment.default.authUrl, "/").concat(token.company_id, "/api/sessions/").concat(token.username), {
        credentials: 'include',
        method: 'DELETE'
      }).then(function (response) {
        if (response.ok) {
          obj.store.unloadAll();
          obj.router.transitionTo('login');
        } else {
          obj.flashMessages.negative('There was an error logging out. Please click here to try again.', {
            sticky: true,
            onDestroy: function onDestroy() {
              if (!obj.isDestroying) {
                obj.router.transitionTo('logout');
              }
            }
          });
        }
      });
    }
  }
});