define("auth-frontend/components/login-form", ["exports", "ember-changeset", "ember-changeset-validations", "fetch", "url", "apps-core-ui/utils/window-proxy", "apps-core-ui/utils/token", "auth-frontend/config/environment", "auth-frontend/validations/user"], function (_exports, _emberChangeset, _emberChangesetValidations, _fetch, _url, _windowProxy, _token, _environment, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var regex = /(^([\w+\\.-]+)?imtapps\.com$)|(^(\w+\.)?apps-system\.com$)|(^([\w+\\.-]+)?pdspectrum\.com$)|(^([\w+\\.-]+)?priorityrate\.com$)/;

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    next: null,
    url: '',
    creds: Ember.computed(function () {
      return new _emberChangeset.default({
        username: '',
        password: ''
      }, (0, _emberChangesetValidations.default)(_user.default.login), _user.default.login);
    }),
    hasValidNext: function hasValidNext() {
      if (this.next) {
        var url = new _url.default(this.next);
        return url.hostname.search(regex) >= 0;
      }

      return false;
    },
    handleSuccess: function handleSuccess(body) {
      this.set('loading', true);
      var regexResult = document.cookie.match('(auth=[^;\\s]*)');
      var token = regexResult ? regexResult[1] : 'auth=';

      if (body.data.attributes.mfa) {
        var next = (this.next ? "&next=".concat(this.next) : '').replace('#', '%23');

        _windowProxy.default.changeLocation("".concat(_environment.default.authUrl, "/mfa/?username=").concat(this.get('creds.username')).concat(next));
      } else if (this.hasValidNext()) {
        _windowProxy.default.changeLocation("".concat(this.next, "?").concat(token));
      } else {
        this.handleSuccessCompanyRedirects(body, token);
      }
    },
    handleSuccessCompanyRedirects: function handleSuccessCompanyRedirects(body, token) {
      if (!body.data.attributes.company_number) {
        _windowProxy.default.changeLocation("".concat(_environment.default.dashboardUrl, "?").concat(token));
      } else {
        // This is necessary because we are loading a company with the
        // api_key for company specific login without unloading this
        // when we try to get the redirect_url it will be undefined
        var publicCompany = this.store.peekRecord('company', body.data.attributes.company_number);

        if (publicCompany) {
          this.store.unloadRecord(publicCompany);
        }

        this.store.findRecord('company', body.data.attributes.company_number).then(function (company) {
          var companyUrl = company.redirect_url !== '/' ? company.redirect_url : _environment.default.dashboardUrl;

          if (companyUrl) {
            _windowProxy.default.changeLocation("".concat(companyUrl, "?").concat(token));
          }
        });
      }
    },
    handleFailure: function handleFailure(body) {
      var _this = this;

      var token = (0, _token.getToken)(this.router);

      if ((0, _token.getTokenData)(token) && (0, _token.getTokenData)(token).change_password) {
        var redirect = Ember.get(body, 'errors.meta.redirect_url');
        this.flashMessages.negative('Your password has expired. Please change your password below.');
        return _windowProxy.default.changeLocation(redirect);
      }

      if (Array.isArray(body.errors)) {
        return body.errors.forEach(function (error) {
          _this.flashMessages.negative(error.detail);
        });
      }

      if (body.errors) {
        return this.flashMessages.negative(body.errors.detail);
      }

      this.flashMessages.negative('An error occurred');
    },
    login: function login() {
      var _this2 = this;

      this.creds.validate().then(function () {
        if (_this2.creds.isValid) {
          (0, _fetch.default)("".concat(_environment.default.authUrl, "/").concat(_this2.url, "api/sessions/?username=").concat(_this2.creds.get('username')), {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
              data: {
                attributes: {
                  username: _this2.creds.get('username'),
                  password: _this2.creds.get('password')
                },
                type: 'sessions'
              }
            }),
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json'
            }
          }).then(function (response) {
            response.json().then(function (body) {
              if (response.ok) {
                _this2.handleSuccess(body);
              } else {
                _this2.handleFailure(body);
              }
            });
          });
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "login", [_dec], Object.getOwnPropertyDescriptor(_obj, "login"), _obj)), _obj)));

  _exports.default = _default;
});