define("auth-frontend/templates/components/step-nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rXm4blfm",
    "block": "{\"symbols\":[\"@name\",\"@activate\",\"&default\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"active step\"],[15,1,[32,1]],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"step\"],[16,1,[32,1]],[24,6,\"javascript:void(0)\"],[4,[38,1],[[32,0],[30,[36,0],[[32,2],[32,1]],null]],null],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"action\",\"isActive\",\"if\"]}",
    "moduleName": "auth-frontend/templates/components/step-nav-item.hbs"
  });

  _exports.default = _default;
});