define("auth-frontend/routes/reset-password", ["exports", "auth-frontend/utils/decode-token"], function (_exports, _decodeToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      auth: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.auth) {
        var token = (0, _decodeToken.default)(params.auth);
        return this.store.findRecord('user', token.user_id, {
          adapterOptions: {
            companyId: token.company_id,
            auth: params.auth
          }
        });
      }

      return this.transitionTo('not-found');
    }
  });

  _exports.default = _default;
});