define("auth-frontend/validators/validate-confirmation-on-model-or-changeset", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators"], function (_exports, _validationErrors, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConfirmation;

  function validateConfirmation() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (key, newValue, _oldValue, changes, content) {
      var model = Ember.get(changes, options.on) ? changes : content;
      var result = (0, _emberValidators.validate)('confirmation', newValue, options, model, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});