define("auth-frontend/templates/components/password-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gWs4gHJm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui right labeled input\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@type\",\"@id\",\"@value\",\"@key-up\"],[[34,3],[30,[36,4],[[35,2],[35,2],\"password\"],null],[34,5],[30,[36,6],[[32,0],\"keyUpAction\"],null]]],null],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"ui label \",[30,[36,4],[[35,7],\"red\"],null]]]],[4,[38,6],[[32,0],\"toggleShowPassword\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"visibility icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-progress\",[],[[\"@percent\",\"@class\"],[[30,[36,1],[[35,0]],null],\"red indicating bottom attached\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,1,\"password-strength\"],[15,0,[31,[[34,2],\" bar\"]]],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"passwordStrength\",\"concat\",\"identifier\",\"passwordInputType\",\"if\",\"value\",\"action\",\"error\",\"noBar\",\"unless\"]}",
    "moduleName": "auth-frontend/templates/components/password-field.hbs"
  });

  _exports.default = _default;
});