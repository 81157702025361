define("auth-frontend/services/company-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentCompany: Ember.computed(function () {
      return this.store.peekRecord('user', 'details').companyNumber;
    })
  });

  _exports.default = _default;
});