define("auth-frontend/routes/register", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "auth-frontend/utils/decode-token"], function (_exports, _unauthenticatedRouteMixin, _decodeToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      auth: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('params', params);

      if (params.auth) {
        var token = (0, _decodeToken.default)(params.auth);
        return this.store.findRecord('user', token.user_id, {
          adapterOptions: {
            companyId: params.companyId,
            auth: params.auth
          }
        }).catch(function () {
          return {};
        });
      }

      return null;
    },
    setupController: function setupController(controller, model) {
      controller.set('params', this.params);

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});