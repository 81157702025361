define("auth-frontend/templates/components/table-header-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J0RpyVj/",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"th\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "auth-frontend/templates/components/table-header-column.hbs"
  });

  _exports.default = _default;
});