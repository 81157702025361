define("auth-frontend/components/ui-app-menu-item", ["exports", "apps-core-ui/components/ui-app-menu-item"], function (_exports, _uiAppMenuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiAppMenuItem.default;
    }
  });
});