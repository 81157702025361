define("auth-frontend/serializers/user", ["exports", "auth-frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, type, payload, id, requestType) {
      var _this = this;

      if (!payload.data) {
        payload.username = payload.user.username;
        payload.first_name = payload.user.first_name;
        payload.last_name = payload.user.last_name;
        payload.company_number = payload.user.company;
        payload.is_staff = payload.user.is_staff;
        payload.is_superuser = payload.user.is_superuser;
        payload.is_active = payload.user.is_active;
        payload.email = payload.user.email;
        payload.has_mfa = payload.user.has_mfa;
        return this._super(store, type, {
          'data': {
            'attributes': payload,
            'type': 'users',
            'id': 'details'
          }
        }, id, requestType);
      }

      if (payload.data.length) {
        payload.data.forEach(function (user) {
          return _this.setInvitedUsername(user);
        });
      } else {
        this.setInvitedUsername(payload.data);
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var serialized = this._super(snapshot, options);

      if (snapshot.id && !snapshot.record.changedAttributes().password) {
        delete serialized.data.attributes.password;
      }

      return serialized;
    },
    setInvitedUsername: function setInvitedUsername(user) {
      if (user.attributes && user.attributes.username.indexOf('INVITED USER') >= 0) {
        user.attributes.username = '';
      }

      if (user.attributes && user.attributes.username.includes('TEMP USER')) {
        user.attributes.username = '';
      }
    }
  });

  _exports.default = _default;
});