define("auth-frontend/templates/parent/users/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6YAT02yK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"app-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui massive active text loader\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Loading\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "auth-frontend/templates/parent/users/loading.hbs"
  });

  _exports.default = _default;
});