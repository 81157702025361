define("auth-frontend/components/audit-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    store: Ember.inject.service(),
    eventbus: Ember.inject.service(),
    grantedBy: Ember.computed(function () {
      var _this = this;

      var PromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
      return PromiseProxy.create({
        promise: Ember.RSVP.hash({
          inherited: this.getWithDefault('inheritedRelations', [])
        }).then(function (args) {
          var lists = [];
          args.inherited.forEach(function (item) {
            lists.pushObject(item);
          });
          var idsUsed = [];
          var data = lists.filter(function (relation, _, list) {
            //eslint-disable-line
            if (_this.get('modelB.constructor.modelName') !== 'permission' && _this.get('modelA.constructor.modelName') !== 'permission' && relation.get('permission.id')) {
              return undefined;
            }

            if (relation.get("".concat(_this.get('modelB.constructor.modelName'), ".id")) === _this.get('modelB.id') && relation.get("".concat(_this.get('modelA.constructor.modelName'), ".id")) === _this.get('modelA.id') && relation.get('inherited') === _this.inherited && !idsUsed.includes(relation.id.split('-')[0])) {
              idsUsed.pushObject(relation.id.split('-')[0]);
              return relation;
            }

            var hasCompany = relation.get('company') === _this.get('modelA.id');

            var permissionMatch = relation.get('permission.id') === _this.get('modelB.id');

            if (hasCompany && permissionMatch) {
              idsUsed.pushObject(relation.id.split('-')[0]);
              return relation;
            }

            return undefined;
          }).compact();

          var grantedBy = _this.getGrantedBy(data);

          return {
            'description': grantedBy
          };
        })
      });
    }),
    getGrantedBy: function getGrantedBy(permissionRelations) {
      var grantedBy = '';

      if (permissionRelations.length === 1) {
        this.set('tempRelation', permissionRelations[0].id.indexOf('temp') >= 0);
        grantedBy = permissionRelations.length ? permissionRelations[0].get('createdBy') : '';
        this.set('thirdParam', permissionRelations[0]);
      } else if (permissionRelations.length > 1) {
        grantedBy = "(".concat(permissionRelations.length, ")");
      }

      return grantedBy;
    },
    toggleAudits: function toggleAudits() {
      this.eventbus.trigger('toggle-audit-display', this.modelA, this.modelB, this.thirdParam, this.agency, this.inherited);
    },
    toggleHistory: function toggleHistory() {
      this.eventbus.trigger('toggle-audit-display', this.modelA, this.modelB, this.thirdParam, this.agency, this.inherited, true);
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleAudits", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleAudits"), _obj), _applyDecoratedDescriptor(_obj, "toggleHistory", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleHistory"), _obj)), _obj)));

  _exports.default = _default;
});