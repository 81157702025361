define("auth-frontend/routes/application", ["exports", "apps-core-ui/utils/window-proxy", "apps-core-ui/routes/application"], function (_exports, _windowProxy, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    sessionInvalidated: function sessionInvalidated() {
      return _windowProxy.default.changeLocation("".concat(this.appsCoreHyperlinks.signInUrl));
    }
  });

  _exports.default = _default;
});