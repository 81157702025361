define("auth-frontend/validators/validate-strength", ["exports", "zxcvbn"], function (_exports, _zxcvbn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateStrength;

  function validateStrength() {
    return function (key, newValue, _oldValue, changes, content) {
      var invalidPasswordContents = [Ember.getWithDefault(changes, 'firstName', content.get('firstName')), Ember.getWithDefault(changes, 'lastName', content.get('lastName')), Ember.getWithDefault(changes, 'email', content.get('email')), Ember.getWithDefault(changes, 'username', content.get('username'))];

      if (newValue) {
        var result = (0, _zxcvbn.default)(newValue, invalidPasswordContents);
        return result.score < 2 ? 'Password is not strong enough' : true;
      }

      return true;
    };
  }
});