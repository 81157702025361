define("auth-frontend/controllers/login", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['next'],
    next: null,
    legacyLogout: Ember.computed(function () {
      return _environment.default.legacyLogout;
    })
  });

  _exports.default = _default;
});