define("auth-frontend/validations/user", ["exports", "ember-changeset-validations/validators", "lodash/merge", "auth-frontend/validators/validate-confirmation-on-model-or-changeset", "auth-frontend/validators/validate-strength"], function (_exports, _validators, _merge2, _validateConfirmationOnModelOrChangeset, _validateStrength) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line max-len
  var email = {
    email: (0, _validators.validateFormat)({
      type: 'email'
    })
  };
  var password = {
    password: [(0, _validators.validateLength)({
      min: 8,
      allowNone: false,
      allowBlank: false
    }), (0, _validateStrength.default)()],
    passwordConfirmation: (0, _validateConfirmationOnModelOrChangeset.default)({
      on: 'password'
    })
  };
  var editingPassword = {
    password: [(0, _validators.validateLength)({
      min: 8,
      allowNone: false,
      allowBlank: false
    }), (0, _validateStrength.default)()],
    passwordConfirmation: (0, _validateConfirmationOnModelOrChangeset.default)({
      on: 'password'
    })
  };
  var username = {
    username: [(0, _validators.validatePresence)(true)]
  };
  var forgotPassword = {
    username: [(0, _validators.validatePresence)(true)]
  };
  var emailConfirmation = {
    emailConfirmation: (0, _validateConfirmationOnModelOrChangeset.default)({
      on: 'email'
    })
  };
  var name = {
    firstName: [(0, _validators.validatePresence)(true)],
    lastName: [(0, _validators.validatePresence)(true)]
  };
  var userDefault = (0, _merge2.default)({}, email, emailConfirmation, username, name, password);
  var register = (0, _merge2.default)({}, email, username, password, name);
  var invite = (0, _merge2.default)({}, email, emailConfirmation);
  var edit = (0, _merge2.default)({}, username, email, name);
  var pendingUserEdit = (0, _merge2.default)({}, email);
  var editingEmail = (0, _merge2.default)({}, username, email, emailConfirmation);
  var login = {
    username: (0, _validators.validatePresence)(true),
    password: (0, _validators.validatePresence)(true)
  };
  var _default = {
    invite: invite,
    register: register,
    login: login,
    edit: edit,
    editingEmail: editingEmail,
    editingPassword: editingPassword,
    forgotPassword: forgotPassword,
    password: password,
    userDefault: userDefault,
    pendingUserEdit: pendingUserEdit
  };
  _exports.default = _default;
});