define("auth-frontend/serializers/permission", ["exports", "auth-frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    store: Ember.inject.service(),
    serialize: function serialize(snapshot, options) {
      var serialized = this._super(snapshot, options);

      if (snapshot.id) {
        delete serialized.data.attributes.code;
      }

      if (!this.store.peekRecord('user', 'details').isStaff) {
        delete serialized.data.attributes.system;
        delete serialized.data.attributes.description;
      }

      return serialized;
    }
  });

  _exports.default = _default;
});