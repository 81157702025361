define("auth-frontend/components/loading-dropdown", ["exports", "apps-core-ui/components/loading-dropdown"], function (_exports, _loadingDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loadingDropdown.default;
    }
  });
});