define("auth-frontend/controllers/register", ["exports", "ember-changeset", "ember-changeset-validations", "auth-frontend/validations/user", "auth-frontend/utils/set-validations-watcher"], function (_exports, _emberChangeset, _emberChangesetValidations, _user, _setValidationsWatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    event: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['auth'],
    auth: null,
    actualUser: Ember.computed.reads('model.id'),
    userChangeset: Ember.computed('auth', 'currentChangeset', 'emailChanged', 'model.invite', 'passwordChanged', function () {
      var currentChangeset = this.currentChangeset;

      if (currentChangeset) {
        currentChangeset.execute();
      }

      this.model.set('includePassword', true);
      var userChangeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(_user.default.register), _user.default.register, {
        skipValidate: true
      });
      (0, _setValidationsWatcher.default)(this, userChangeset);
      this.set('currentChangeset', userChangeset);
      userChangeset.setProperties({
        auth: this.auth,
        isActive: true
      });
      return userChangeset;
    }),
    save: function save() {
      var _this = this;

      this.userChangeset.validate().then(function () {
        if (_this.emailChanged && _this.get('userChangeset.email') !== _this.get('userChangeset.emailConfirmation')) {
          _this.userChangeset.addError('emailConfirmation', "Email confirmation doesn't match email");
        }

        if (_this.get('userChangeset.isValid')) {
          _this.userChangeset.save({
            adapterOptions: {
              companyId: _this.get('params.companyId'),
              auth: _this.auth
            }
          }).then(function (user) {
            _this.flashMessages.positive('Successfully registered! Please login to continue.');

            _this.transitionToRoute('login');
          }, function (error) {
            var _error$errors;

            if (error !== null && error !== void 0 && (_error$errors = error.errors) !== null && _error$errors !== void 0 && _error$errors.username) {
              return _this.flashMessages.negative(error.errors.username[0]);
            }

            _this.flashMessages.negative('There was an error registering. Please try again.');
          });
        }
      });
    },
    validateField: function validateField(field) {
      this.userChangeset.validate(field);
    },
    setEmailChanged: function setEmailChanged() {
      this.set('emailChanged', true);
    }
  }, (_applyDecoratedDescriptor(_obj, "save", [_dec], Object.getOwnPropertyDescriptor(_obj, "save"), _obj), _applyDecoratedDescriptor(_obj, "validateField", [_dec2], Object.getOwnPropertyDescriptor(_obj, "validateField"), _obj), _applyDecoratedDescriptor(_obj, "setEmailChanged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setEmailChanged"), _obj)), _obj)));

  _exports.default = _default;
});