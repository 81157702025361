define("auth-frontend/routes/forbidden", ["exports", "apps-core-ui/routes/forbidden"], function (_exports, _forbidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _forbidden.default;
    }
  });
});