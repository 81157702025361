define("auth-frontend/utils/decode-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = decodeToken;

  function decodeToken(auth) {
    return JSON.parse(atob(auth.split('.')[1]));
  }
});