define("auth-frontend/templates/components/group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "suRmAwlH",
    "block": "{\"symbols\":[\"@group\"],\"statements\":[[10,\"form\"],[14,0,\"ui form\"],[14,1,\"group-form\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"required field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@id\",\"@value\"],[\"name\",[32,1,[\"name\"]]]],null],[2,\"\\n\"],[6,[37,0],[[32,1,[\"error\",\"name\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,1,\"name-validation\"],[14,0,\"ui negative message\"],[12],[1,[32,1,[\"error\",\"name\",\"validation\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Description\"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@id\",\"@value\"],[\"description\",[32,1,[\"description\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "auth-frontend/templates/components/group-form.hbs"
  });

  _exports.default = _default;
});