define("auth-frontend/utils/set-validations-watcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setValidationsWatcher;

  function setValidationsWatcher(container, changeset) {
    changeset.on('beforeValidation', function (key) {
      container.set("".concat(key, "Validating"), true);
    });
    changeset.on('afterValidation', function (key) {
      container.set("".concat(key, "Validating"), false);
    });
  }
});