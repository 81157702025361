define("auth-frontend/routes/forgot-password", ["exports", "ember-changeset-validations", "ember-changeset", "ember-simple-auth/mixins/unauthenticated-route-mixin", "auth-frontend/validations/user"], function (_exports, _emberChangesetValidations, _emberChangeset, _unauthenticatedRouteMixin, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      document.title = 'Forgot Password';
      return new _emberChangeset.default({
        'username': ''
      }, (0, _emberChangesetValidations.default)(_user.default.forgotPassword), _user.default.forgotPassword);
    }
  });

  _exports.default = _default;
});