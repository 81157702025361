define("auth-frontend/utils/parse-error-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseErrorMessages;

  function parseErrorMessages(data) {
    return Object.keys(data.errors).reduce(function (previousValue, currentValue) {
      var _data$errors$currentV;

      var message = 'Oops! You have encountered an unknown error. Please try again.';

      if ((_data$errors$currentV = data.errors[currentValue]) !== null && _data$errors$currentV !== void 0 && _data$errors$currentV.title) {// The default ember data error message would confuse the user
      } else {
        message = Object.values(data.errors[currentValue]);
      }

      return previousValue.concat(message);
    }, []);
  }
});