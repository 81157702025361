define("auth-frontend/controllers/reset-password", ["exports", "ember-changeset", "ember-changeset-validations", "auth-frontend/validations/user", "auth-frontend/utils/decode-token", "auth-frontend/utils/set-validations-watcher"], function (_exports, _emberChangeset, _emberChangesetValidations, _user, _decodeToken, _setValidationsWatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    flashMessages: Ember.inject.service(),
    queryParams: ['auth'],
    auth: null,
    passwordChangeset: Ember.computed('model', function () {
      this.model.setProperties({
        includePassword: true
      });
      var passwordChangeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(_user.default.password), _user.default.password);
      (0, _setValidationsWatcher.default)(this, passwordChangeset);
      passwordChangeset.set('password', null);
      return passwordChangeset;
    }),
    resetPassword: function resetPassword() {
      var _this = this;

      this.passwordChangeset.validate().then(function () {
        if (_this.get('passwordChangeset.isValid')) {
          var token = (0, _decodeToken.default)(_this.auth);

          _this.passwordChangeset.save({
            adapterOptions: {
              auth: _this.auth,
              companyId: token.company_id
            }
          }).then(function (user) {
            _this.flashMessages.positive('Password successfully reset! Please login to continue.');

            _this.transitionToRoute('login');
          }, function () {
            _this.flashMessages.negative('There was an error resetting the password. Please try again.');
          });
        }
      });
    },
    keyDownAction: function keyDownAction() {
      if (this.passwordScore < 2) {
        this.passwordChangeset.addError('password', 'Password is not strong enough');
      }
    },
    passwordKeyUp: function passwordKeyUp() {
      if (this.passwordScore < 2) {
        this.passwordChangeset.addError('password', 'Password is not strong enough');
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "resetPassword", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetPassword"), _obj), _applyDecoratedDescriptor(_obj, "keyDownAction", [_dec2], Object.getOwnPropertyDescriptor(_obj, "keyDownAction"), _obj), _applyDecoratedDescriptor(_obj, "passwordKeyUp", [_dec3], Object.getOwnPropertyDescriptor(_obj, "passwordKeyUp"), _obj)), _obj)));

  _exports.default = _default;
});