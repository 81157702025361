define("auth-frontend/controllers/logout", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    showDetails: false,
    legacyLogout: Ember.computed(function () {
      return _environment.default.legacyLogout;
    }),
    toggleDetails: function toggleDetails() {
      this.toggleProperty('showDetails');
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleDetails", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleDetails"), _obj)), _obj)));

  _exports.default = _default;
});