define("auth-frontend/templates/components/step-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eVjp1YjD",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"ui button\"],[17,1],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,2],[[35,1],[35,0]],null]],null],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"passthrough\",\"clickAction\",\"fn\",\"on\",\"isHidden\",\"unless\"]}",
    "moduleName": "auth-frontend/templates/components/step-button.hbs"
  });

  _exports.default = _default;
});