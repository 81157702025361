define("auth-frontend/templates/parent/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQhjBN3Z",
    "block": "{\"symbols\":[],\"statements\":[[8,\"manage-user-info\",[],[[\"@model\",\"@editing\",\"@setEditing\",\"@cancel\",\"@new\",\"@copy\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"editing\",\"setEditing\",\"cancel\",\"new\",\"copy\"]}",
    "moduleName": "auth-frontend/templates/parent/users/edit.hbs"
  });

  _exports.default = _default;
});