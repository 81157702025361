define("auth-frontend/utils/window-proxy", ["exports", "apps-core-ui/utils/window-proxy"], function (_exports, _windowProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _windowProxy.default.scrollTo = function (loc) {
    window.scrollTo(loc, loc);
  };

  var _default = _windowProxy.default;
  _exports.default = _default;
});