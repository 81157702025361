define("auth-frontend/templates/parent/groups/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5lbefjjF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ten wide left aligned column\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Groups\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"right aligned right floated six wide column\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"right aligned right floated header inline\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,\"data-position\",\"top right\"],[14,\"data-tooltip\",\"Create Group\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"black big add icon\"],[15,\"onClick\",[30,[36,0],[[32,0],\"addGroup\"],null]],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"groups\",[],[[\"@groups\",\"@rowMenuVisible\",\"@view\",\"@edit\",\"@delete\",\"@showEdit\",\"@showDelete\"],[[34,3],true,[34,4],[34,5],[34,6],true,true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hasEditPerm\",\"if\",\"model\",\"view\",\"edit\",\"delete\"]}",
    "moduleName": "auth-frontend/templates/parent/groups/index.hbs"
  });

  _exports.default = _default;
});