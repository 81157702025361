define("auth-frontend/adapters/user", ["exports", "auth-frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    getCompany: function getCompany(snapshot) {
      return Ember.get(snapshot, 'adapterOptions.companyId') || this.company;
    },
    getQuery: function getQuery(snapshot) {
      return Ember.get(snapshot, 'adapterOptions.auth') ? "?auth=".concat(snapshot.adapterOptions.auth) : '';
    },
    urlForCreateRecord: function urlForCreateRecord(_, snapshot) {
      if (snapshot.adapterOptions && snapshot.adapterOptions.copy) {
        return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/").concat(snapshot.adapterOptions.copy, "/copy");
      }

      return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/");
    },
    urlForFindRecord: function urlForFindRecord(id, _, snapshot) {
      if (id === 'details') {
        return "".concat(this.host, "/").concat(this.namespace, "/user/").concat(id, "/");
      }

      return "".concat(this.host, "/").concat(this.getCompany(snapshot), "/").concat(this.namespace, "/users/").concat(id, "/").concat(this.getQuery(snapshot));
    },
    urlForFindAll: function urlForFindAll(_, snapshot) {
      var url = "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/?fields[users]=first_name,last_name,username,email,is_active");

      if (snapshot.adapterOptions && snapshot.adapterOptions.agency_only) {
        url = "".concat(url, "&agency_only=true");
      }

      return url;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, _, snapshot) {
      return "".concat(this.host, "/").concat(this.getCompany(snapshot), "/").concat(this.namespace, "/users/").concat(id, "/").concat(this.getQuery(snapshot));
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, _, snapshot) {
      return "".concat(this.host, "/").concat(this.getCompany(snapshot), "/").concat(this.namespace, "/users/").concat(id, "/").concat(this.getQuery(snapshot));
    },
    handleResponse: function handleResponse(status) {
      if (status === 403) {
        return {
          errors: [{
            status: '403',
            source: {},
            detail: 'Forbidden'
          }]
        };
      }

      return this._super.apply(this, arguments);
    },
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/");
    },
    urlForQueryRecord: function urlForQueryRecord() {
      return "".concat(this.host, "/").concat(this.company, "/").concat(this.namespace, "/users/");
    }
  });

  _exports.default = _default;
});