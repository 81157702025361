define("auth-frontend/templates/components/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TM2zTWGO",
    "block": "{\"symbols\":[\"@showCheckbox\",\"&default\",\"@loading\"],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"ui active centered inline text loader\"],[12],[2,\"Loading\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"table\"],[14,0,\"ui very basic selectable table\"],[12],[2,\"\\n          \"],[18,2,[[30,[36,1],null,[[\"Header\",\"Body\",\"HeaderColumn\",\"Row\",\"Message\",\"Filter\"],[[30,[36,0],[\"table-header\"],[[\"showCheckbox\"],[[32,1]]]],[30,[36,0],[\"table-body\"],null],[30,[36,0],[\"table-header-column\"],null],[30,[36,0],[\"table-row\"],[[\"showCheckbox\"],[[32,1]]]],[30,[36,0],[\"table-message\"],null],[30,[36,0],[\"table-filter\"],null]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "moduleName": "auth-frontend/templates/components/table.hbs"
  });

  _exports.default = _default;
});