define("auth-frontend/templates/components/confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oekS4uTu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"close icon\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"Confirmation\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[14,1,\"confirmation-modal\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"confirmation-text\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui right green floated button\"],[24,1,\"no\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"closeModal\"],null],[12],[2,\"No\"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui right red floated button\"],[24,1,\"yes\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"yes\"],null],[12],[2,\"Yes\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"header\",\"message\",\"action\"]}",
    "moduleName": "auth-frontend/templates/components/confirmation-modal.hbs"
  });

  _exports.default = _default;
});