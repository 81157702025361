define("auth-frontend/initializers/raven", ["exports", "auth-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var _config$sentry$servic = _environment.default.sentry.serviceName,
        serviceName = _config$sentry$servic === void 0 ? 'raven' : _config$sentry$servic;
    var lookupName = "service:".concat(serviceName);
    var _config$sentry$expose = _environment.default.sentry.exposedPropertyName,
        exposedPropertyName = _config$sentry$expose === void 0 ? 'raven' : _config$sentry$expose;
    application.inject('route', exposedPropertyName, lookupName);
    application.inject('component', exposedPropertyName, lookupName);
    application.inject('controller', exposedPropertyName, lookupName);
    application.inject('model', exposedPropertyName, lookupName);
  }

  var _default = {
    initialize: initialize,
    name: 'raven'
  };
  _exports.default = _default;
});