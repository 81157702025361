define("auth-frontend/templates/components/table-row-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mwkMNkZA",
    "block": "{\"symbols\":[\"&default\",\"@visible\"],\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[14,0,\"right aligned\"],[12],[2,\"\\n    \"],[8,\"ui-dropdown\",[],[[\"@class\",\"@forceSelection\"],[\"actions labeled icon top right pointing\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"more vert icon pointer\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,1],null,[[\"Button\"],[[30,[36,0],[\"table-row-menu-button\"],null]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "moduleName": "auth-frontend/templates/components/table-row-menu.hbs"
  });

  _exports.default = _default;
});