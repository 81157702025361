define("auth-frontend/templates/parent/self-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0OWtRh4P",
    "block": "{\"symbols\":[\"userChangeset\"],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"left aligned left floated fourteen wide column\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,1,\"user-header\"],[12],[2,\"User: \"],[1,[35,9,[\"username\"]]],[2,\" \"],[10,\"em\"],[14,0,\"grey text\"],[12],[1,[30,[36,10],[[35,4],\"(editing)\",\"\"],null]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,13],[[30,[36,12],[[35,9],[35,11]],[[\"skipValidate\"],[true]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"user-form\",[],[[\"@userChangeset\",\"@editing\",\"@showPassword\",\"@disableFields\",\"@toggleChangePassword\",\"@disableMFA\",\"@showEnableMFA\",\"@hasMfa\",\"@model\"],[[32,1],[34,4],[34,5],[34,4],[34,6],[34,7],true,[34,8],[34,9]]],null],[2,\"\\n\\n\"],[6,[37,10],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"ui red inverted button cancel\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[35,0]],null]],null],[12],[2,\"\\n      Cancel\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"ui right floated green inverted button save\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[35,3],[32,1]],null]],null],[12],[2,\"\\n      Save\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"cancel\",\"fn\",\"on\",\"save\",\"editing\",\"showPassword\",\"toggleChangePassword\",\"disableMFA\",\"hasMfa\",\"model\",\"if\",\"validate\",\"changeset\",\"with\"]}",
    "moduleName": "auth-frontend/templates/parent/self-edit.hbs"
  });

  _exports.default = _default;
});