define("auth-frontend/adapters/company", ["exports", "apps-core-ui/adapters/company"], function (_exports, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    urlForFindRecord: function urlForFindRecord(id, model, snapshot) {
      var _snapshot$adapterOpti;

      var host = this.appsCoreApiDomains.configApi;

      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.public) {
        return "".concat(host, "/api/public/company/").concat(id);
      }

      return "".concat(host, "/api/company/").concat(id);
    }
  });

  _exports.default = _default;
});