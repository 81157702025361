define("auth-frontend/models/search", ["exports", "apps-core-ui/models/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _search.default;
    }
  });
});