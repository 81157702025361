define("auth-frontend/routes/parent/company-permissions", ["exports", "apps-core-ui/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    userPermissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.store.peekRecord('user', 'details').get('isStaff')) {
        return this.transitionTo('forbidden');
      }

      if (!this.userPermissions.getPermissions('auth').view) {
        this.transitionTo('/forbidden');
      }

      if (!this.userPermissions.getPermissions('auth').manageCompanyPermissions) {
        this.transitionTo('/forbidden');
      }
    },
    model: function model() {
      return this.store.findRecord('company-permission', this.store.peekRecord('user', 'details').get('companyNumber'));
    }
  });

  _exports.default = _default;
});