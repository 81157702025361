define("auth-frontend/components/confirmation-modal", ["exports", "apps-core-ui/components/core-modal"], function (_exports, _coreModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _coreModal.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    eventbus: Ember.inject.service(),
    defaultHeader: 'Are you sure you would like to cancel?',
    defaultMessage: 'Your changes will not be saved.',
    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);

      this.eventbus.on('toggle-confirmation-modal', this, 'toggle');
    },
    toggle: function toggle(context) {
      var optional_info = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('header', (optional_info === null || optional_info === void 0 ? void 0 : optional_info.header) || this.defaultHeader);
      this.set('message', (optional_info === null || optional_info === void 0 ? void 0 : optional_info.msg) || this.defaultMessage);
      this.set('object', (optional_info === null || optional_info === void 0 ? void 0 : optional_info.object) || null);
      this.set('context', context);
      this.execute('toggle', optional_info);
    },
    closeModal: function closeModal() {
      this.eventbus.off('toggle-confirmation-modal', this.context, 'confirm');
      this.execute('hide');
    },
    yes: function yes() {
      this.eventbus.trigger('confirm', this.object);
      this.eventbus.off('toggle-confirmation-modal', this.context, 'confirm');
      this.execute('hide');
    }
  }, (_applyDecoratedDescriptor(_obj, "closeModal", [_dec], Object.getOwnPropertyDescriptor(_obj, "closeModal"), _obj), _applyDecoratedDescriptor(_obj, "yes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "yes"), _obj)), _obj)));

  _exports.default = _default;
});