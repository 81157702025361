define("auth-frontend/services/permissions-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    permissions: {
      "translation": {
        "257": "view_translations",
        "258": "edit_translations"
      },
      "navigator": {
        "185": "navigator_access",
        "189": "reports_access",
        "190": "reports_renewal",
        "191": "reports_balance",
        "192": "reports_claims",
        "193": "reports_policy",
        "194": "reports_agent",
        "195": "authenticate_agent",
        "206": "reports_premiumnotice",
        "207": "reports_policylistdata",
        "208": "navigator_copytoquote",
        "217": "navigator_changerequest",
        "218": "navigator_webpayments",
        "221": "navigator_restrict_premium",
        "225": "reports_locationdetail",
        "226": "navigator_claim_attachment",
        "229": "navigator_submit_claim",
        "232": "navigator_create_claim",
        "234": "new_claim_system",
        "265": "al3_download_permission",
        "662": "manual_download"
      },
      "dashboard": {
        "186": "appsfiles_access",
        "197": "dashboard_access",
        "216": "vector_emin",
        "219": "mips_webinquiry",
        "220": "zendesk",
        "222": "dashboard_news",
        "227": "imaging_dashboard",
        "230": "activity_access"
      },
      "claims": {
        "137": "claims_user",
        "138": "claim_detail_update",
        "139": "claim_detail_view",
        "140": "claim_admin_access",
        "141": "submit_claim_form_config",
        "142": "loss_recovery_access",
        "143": "payment_edit",
        "144": "reserve_edit",
        "145": "payment_view",
        "146": "reserve_view",
        "147": "view_notes",
        "148": "add_notes",
        "149": "view_reminders",
        "150": "add_reminders_for_self",
        "151": "add_reminders_for_others",
        "152": "view_reminders_for_others",
        "153": "view_activity",
        "154": "assign_tags",
        "155": "assign_substatus",
        "156": "access_documents",
        "157": "delete_claim",
        "158": "payment_account",
        "159": "search",
        "160": "app_menu",
        "161": "payment_void",
        "162": "claims",
        "163": "dashboard",
        "164": "claim_create",
        "233": "claims_access",
        "244": "payment_adjustment",
        "246": "report_loss_run",
        "261": "view_payment_settings",
        "262": "edit_payment_settings",
        "263": "filetrac",
        "629": "edit_electronic_payment_settings",
        "630": "view_electronic_payment_settings",
        "926": "view_claim_transaction_report",
        "959": "edit_adjuster_settings",
        "1058": "edit_electronic_status"
      },
      "admin": {
        "332": "agency_transfer",
        "994": "copy_forms"
      },
      "webinquiry": {
        "165": "submit_change_request",
        "166": "make_payment",
        "167": "reports_access",
        "168": "view_book_of_business",
        "169": "view_payment_history",
        "170": "block_line_item_premium",
        "171": "update_payment_options",
        "172": "download_active_codes",
        "173": "company_options",
        "174": "claim_attachment",
        "175": "view_commission_statements",
        "176": "copy_to_quote",
        "177": "submit_claim",
        "178": "create_claim",
        "179": "claim_summary",
        "180": "new_claim_system",
        "181": "claims_report",
        "243": "agent_download",
        "247": "datatransform_user",
        "366": "hide_deductible"
      },
      "imaging doc_templates": {
        "29": "attachments_folders",
        "30": "attachments_agency_visibility",
        "31": "attachments_delete",
        "32": "update",
        "33": "create",
        "34": "read",
        "35": "attachments_recycle_bin",
        "36": "empty_recycle_bin",
        "37": "related_links",
        "38": "email",
        "39": "annotations_view",
        "40": "annotations_edit"
      },
      "imaging default_claim": {
        "17": "attachments_folders",
        "18": "attachments_agency_visibility",
        "19": "attachments_delete",
        "20": "update",
        "21": "create",
        "22": "read",
        "23": "attachments_recycle_bin",
        "24": "empty_recycle_bin",
        "25": "related_links",
        "26": "email",
        "27": "annotations_view",
        "28": "annotations_edit"
      },
      "imaging auditors": {
        "278": "attachments_folders",
        "279": "attachments_agency_visibility",
        "280": "attachments_delete",
        "281": "update",
        "282": "create",
        "283": "read",
        "284": "attachments_recycle_bin",
        "285": "empty_recycle_bin",
        "286": "related_links",
        "287": "email",
        "288": "annotations_view",
        "289": "annotations_edit"
      },
      "imaging reports": {
        "53": "attachments_folders",
        "54": "attachments_agency_visibility",
        "55": "attachments_delete",
        "56": "update",
        "57": "create",
        "58": "read",
        "59": "attachments_recycle_bin",
        "60": "empty_recycle_bin",
        "61": "related_links",
        "62": "email",
        "63": "annotations_view",
        "64": "annotations_edit"
      },
      "imaging claim": {
        "125": "attachments_folders",
        "126": "attachments_agency_visibility",
        "127": "attachments_delete",
        "128": "update",
        "129": "create",
        "130": "read",
        "131": "attachments_recycle_bin",
        "132": "empty_recycle_bin",
        "133": "related_links",
        "134": "email",
        "135": "annotations_view",
        "136": "annotations_edit"
      },
      "imaging board": {
        "89": "attachments_folders",
        "90": "attachments_agency_visibility",
        "91": "attachments_delete",
        "92": "update",
        "93": "create",
        "94": "read",
        "95": "attachments_recycle_bin",
        "96": "empty_recycle_bin",
        "97": "related_links",
        "98": "email",
        "99": "annotations_view",
        "100": "annotations_edit"
      },
      "imaging forms": {
        "290": "attachments_folders",
        "291": "attachments_agency_visibility",
        "292": "attachments_delete",
        "293": "update",
        "294": "create",
        "295": "read",
        "296": "attachments_recycle_bin",
        "297": "empty_recycle_bin",
        "298": "related_links",
        "299": "email",
        "300": "annotations_view",
        "301": "annotations_edit"
      },
      "imaging management": {
        "41": "attachments_folders",
        "42": "attachments_agency_visibility",
        "43": "attachments_delete",
        "44": "update",
        "45": "create",
        "46": "read",
        "47": "attachments_recycle_bin",
        "48": "empty_recycle_bin",
        "49": "related_links",
        "50": "email",
        "51": "annotations_view",
        "52": "annotations_edit"
      },
      "imaging conversion": {
        "563": "attachments_folders",
        "564": "attachments_agency_visibility",
        "565": "attachments_delete",
        "566": "update",
        "567": "create",
        "568": "read",
        "569": "attachments_recycle_bin",
        "570": "empty_recycle_bin",
        "571": "related_links",
        "572": "email",
        "573": "annotations_view",
        "574": "annotations_edit"
      },
      "imaging reference": {
        "77": "attachments_folders",
        "78": "attachments_agency_visibility",
        "79": "attachments_delete",
        "80": "update",
        "81": "create",
        "82": "read",
        "83": "attachments_recycle_bin",
        "84": "empty_recycle_bin",
        "85": "related_links",
        "86": "email",
        "87": "annotations_view",
        "88": "annotations_edit"
      },
      "imaging policy": {
        "5": "attachments_folders",
        "6": "attachments_agency_visibility",
        "7": "attachments_delete",
        "8": "update",
        "9": "create",
        "10": "read",
        "11": "attachments_recycle_bin",
        "12": "empty_recycle_bin",
        "13": "related_links",
        "14": "email",
        "15": "annotations_view",
        "16": "annotations_edit"
      },
      "imaging default_policy": {
        "113": "attachments_folders",
        "114": "attachments_agency_visibility",
        "115": "attachments_delete",
        "116": "update",
        "117": "create",
        "118": "read",
        "119": "attachments_recycle_bin",
        "120": "empty_recycle_bin",
        "121": "related_links",
        "122": "email",
        "123": "annotations_view",
        "124": "annotations_edit"
      },
      "imaging need_to_print": {
        "266": "attachments_folders",
        "267": "attachments_agency_visibility",
        "268": "attachments_delete",
        "269": "update",
        "270": "create",
        "271": "read",
        "272": "attachments_recycle_bin",
        "273": "empty_recycle_bin",
        "274": "related_links",
        "275": "email",
        "276": "annotations_view",
        "277": "annotations_edit"
      },
      "imaging company": {
        "101": "attachments_folders",
        "102": "attachments_agency_visibility",
        "103": "attachments_delete",
        "104": "update",
        "105": "create",
        "106": "read",
        "107": "attachments_recycle_bin",
        "108": "empty_recycle_bin",
        "109": "related_links",
        "110": "email",
        "111": "annotations_view",
        "112": "annotations_edit"
      },
      "imaging agency": {
        "65": "attachments_folders",
        "66": "attachments_agency_visibility",
        "67": "attachments_delete",
        "68": "update",
        "69": "create",
        "70": "read",
        "71": "attachments_recycle_bin",
        "72": "empty_recycle_bin",
        "73": "related_links",
        "74": "email",
        "75": "annotations_view",
        "76": "annotations_edit"
      },
      "imaging accountants": {
        "302": "attachments_folders",
        "303": "attachments_agency_visibility",
        "304": "attachments_delete",
        "305": "update",
        "306": "create",
        "307": "read",
        "308": "attachments_recycle_bin",
        "309": "empty_recycle_bin",
        "310": "related_links",
        "311": "email",
        "312": "annotations_view",
        "313": "annotations_edit"
      },
      "vector": {
        "187": "mutual_access",
        "188": "quote_access",
        "198": "quote_application",
        "200": "quote_anonymous",
        "201": "quote_startapplication",
        "202": "quote_submitapplication",
        "203": "quote_releaseapplication",
        "204": "quote_issueapplication",
        "205": "quote_rejectapplication",
        "209": "evaluation_access",
        "210": "quote_acceptviolation",
        "211": "quote_customratingtier",
        "212": "edit_group_permissions",
        "213": "quote_revertapplication",
        "214": "vector_perreps",
        "215": "create_new_groups",
        "223": "vector_webpayments",
        "224": "binder_access",
        "228": "hide_blank_applications",
        "231": "vector_user_only_access",
        "255": "change_producer",
        "256": "revert_to_quote"
      },
      "activity": {
        "695": "reminders_all",
        "992": "manage_subscriptions_blocked",
        "993": "manage_subscription_others",
        "1025": "get_daily_subscribers"
      },
      "config": {
        "497": "add_agency",
        "794": "edit_accounts",
        "860": "product_api_access"
      },
      "agent-download": {
        "259": "ivans_agent"
      },
      "search": {
        "464": "upload_to_search"
      },
      "lender notifications": {
        "431": "create_emin"
      },
      "imaging config": {
        "236": "imaging_backup",
        "264": "imaging_to_imaging_conversion",
        "728": "overwrite_username"
      },
      "payments": {
        "248": "bills",
        "249": "due",
        "250": "pay",
        "251": "policy-settings",
        "252": "vector_payments",
        "253": "payments",
        "254": "vendor-balance-lookup",
        "398": "transaction_report",
        "827": "outbound_payments"
      },
      "rolodex": {
        "596": "rolodex_admin"
      },
      "auth": {
        "1": "edit_group_permissions",
        "2": "create_new_groups",
        "3": "manage_users",
        "4": "change_password",
        "237": "export_permissions",
        "238": "view",
        "239": "edit",
        "240": "assign_multiple_agencies",
        "241": "require_mfa",
        "242": "spoof_user",
        "365": "manage_company_permissions",
        "575": "change_password_blocked",
        "893": "spoof_company"
      },
      "site": {
        "184": "cpanel_access",
        "196": "help_edit",
        "199": "usersettings_access",
        "235": "appsfiles_access",
        "245": "authenticate_agent"
      },
      "insured portal": {
        "761": "ip_settings"
      },
      "rate": {
        "182": "view_rate_sets",
        "183": "edit_rate_sets"
      },
      "spectrum": {
        "530": "spectrum_user",
        "531": "spectrum_accounting",
        "532": "spectrum_rater"
      }
    }
  });

  _exports.default = _default;
});