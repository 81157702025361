define("auth-frontend/components/sidebar-buttons", ["exports", "apps-core-ui/components/sidebar-buttons"], function (_exports, _sidebarButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sidebarButtons.default;
    }
  });
});