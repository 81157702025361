define("auth-frontend/templates/components/step-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QMo2YEd6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"StepNav\",\"StepNavItem\",\"StepBody\",\"StepButton\"],[[30,[36,0],[\"step-nav\"],null],[30,[36,0],[\"step-nav-item\"],[[\"activeStep\",\"activate\"],[[32,0,[\"active\"]],[32,0,[\"activate\"]]]]],[30,[36,0],[\"step-body\"],[[\"activeStep\"],[[32,0,[\"active\"]]]]],[30,[36,0],[\"step-button\"],[[\"activeStep\",\"previous\",\"next\"],[[32,0,[\"active\"]],[32,0,[\"previous\"]],[32,0,[\"next\"]]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "auth-frontend/templates/components/step-controller.hbs"
  });

  _exports.default = _default;
});