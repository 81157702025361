define("auth-frontend/components/dropdown-agency", ["exports", "apps-core-ui/components/dropdown-agency"], function (_exports, _dropdownAgency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dropdownAgency.default;
    }
  });
});