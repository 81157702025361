define("auth-frontend/initializers/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (options && options.until && options.until === '3.20.0') {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});