define("auth-frontend/validations/group", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var name = {
    name: (0, _validators.validateLength)({
      min: 1,
      max: 20,
      allowNone: false,
      allowBlank: false
    })
  };
  var _default = {
    name: name
  };
  _exports.default = _default;
});